<template>
  <div class="Awarp">
    <dashCard
      class="carbonBox3"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox3_subitem','电能分项', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div class="card-content1">
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 300px"
          @change="handleChange"
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM'].length >= 3 &&
              searchObj['ITEM'].findIndex((value) => value === item.code) === -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox3
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
import { number } from "echarts";
export default {
  name: "carbonBox3",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.type = [];
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      type: [],
      itemList: [],
      // itemList: [
      //     { code: '空调',name:'空调' },
      //     { code: '照明',name:'照明' },
      //     { code: '路灯',name:'路灯' },
      //     { code: '插座',name:'插座' },
      //     { code: '动力',name:'动力' },
      //     { code: '风机',name:'风机' },
      //     { code: '电机',name:'电机' },
      //     { code: '消防',name:'消防' },
      //     { code: '电梯',name:'电梯' },
      //     { code: '充电桩',name:'充电桩' },
      //     { code: '水泵',name:'水泵' },
      //     { code: '厨房',name:'厨房' },
      //     { code: '空压机',name:'空压机' },
      //     { code: '新风机',name:'新风机' },
      //     { code: '其他负载',name:'其他负载' },
      //     { code: '污水处理',name:'污水处理' },
      //     { code: '外部电源',name:'外部电源' },
      //     { code: '数据中心',name:'数据中心' },
      //     { code: '通信设备',name:'通信设备' },
      // ],
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
       
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: [],
      },
      gridLeft: [],
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: [
        "#2D76FE",
        "#4DB8FF",
        "#FFB200",
        "#3E6DFF",
        "#536EBF",
        "#6E93FF",
        "#5FEBFF",
        "#8AF1FF",
        "#8AF1FF",
      ],
    };
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
    dashboardOptions() {
      return this.$store.state.dashboardOptions;
    },
    assetDic() {
      return this.dashboardOptions.assetDic;
    },
    electricDic() {
      return this.dashboardOptions.electricDic;
    },
  },
  methods: {
    getItemList() {
        
        let arr = [];
        parseFloat
        this.electricDic.forEach((item) => {
          if (item) {
            if (item.code == "联络") {
            } else {
              arr.push({
                code: item.code,
                name: item.code,
              });
            }
          }
        });
        this.itemList = arr;
        let lang = sessionStorage.getItem("locale") || 'CH'
        if(lang=='EN'){
         
          for(let item of this.itemList){
              if(item.name =='光伏'){
                  item.name = 'photovoltaic'
              }
              if(item.name =='动力'){
                  item.name = 'power'
              }
              if(item.name =='市电'){
                  item.name = 'electric supply'
              }
              if(item.name =='电机'){
                  item.name = 'electrical machinery'
              }
          }
        }

        if (this.itemList.length > 0) {
        this.searchObj.ITEM.push(this.itemList[0].code)
        this.getData()
      }
    },
    handleChange() {
      this.getData();
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },

    //每月报警预警数
    getData() {
      var url = Config.dev_url + "/api-apps-v2/api/v2/electric/subterm/statistics";
      if(this.searchObj.ITEM.length==0){
        return
      }
      var data = {
        // machineStation:"JG221027044",
        machineStation: this.currentSelectDeptInfo.CODE,
        machineType: this.searchObj.ITEM,
        // type:"year",//（年：year，月：month，周：week，日：day）
        // startTime:"2022-03-01 00:00:00",
        // endTime:"2022-12-31 23:59:59",
      };
      Object.assign(data, this.transformSearch());
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          if (res.data.code == 0) {
            this.detailInfo = {};
            var chart1 = {
              categoryData: res.data.data.categoryData,
              seriesData: [],
            };
            res.data.data.seriesData.forEach((item) => {
              var obj = {
                name: item.type,
                value: item.count,
              };
              chart1.seriesData.push(obj);
            });
            this.detailInfo.chart1 = chart1;
            let newarr = [];
            this.detailInfo.chart1.seriesData.forEach((item) => {
              item.value.forEach((val) => {
                newarr.push(val);
              });
            });
            let lang = sessionStorage.getItem("locale") || 'CH'
            console.log(this.detailInfo.chart1.seriesData)
            
            if (this.getNull(newarr) == newarr.length) {
              this.gridLeft = [0, 0, 0, 40];
            } else {
              this.gridLeft = [0, 0, 0, 0];
            }
            if(lang=='EN'){
            
              for(let item of this.detailInfo.chart1.seriesData){
                  this.gridLeft = [0, 0, 0, 120];
                  if(item.name =='光伏'){
                      item.name = 'photovoltaic'
                  }
                  if(item.name =='动力'){
                      item.name = 'power'
                  }
                  if(item.name =='市电'){
                      item.name = 'electric supply'
                  }
                  if(item.name =='电机'){
                      item.name = 'electrical machinery'
                  }
              }
            }
            this.initEchart();
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    getData0() {
      this.detailInfo = {};
      var chart1 = {
        categoryData: ["Jan", "Feb", "Mar", "Apr", "May"],
        seriesData: [
          {
            name: "空调",
            value: [1130, 2132, 1247, 2138, 1800],
          },
          {
            name: "照明",
            value: [1200, 1200, 1700, 1800, 2132],
          },
          {
            name: "电梯",
            value: [2100, 2200, 1700, 1800, 2032],
          },
          {
            name: "充电桩",
            value: [1300, 1200, 1500, 1200, 1132],
          },
          {
            name: "其他",
            value: [3200, 2200, 2700, 1800, 2000],
          },
        ],
      };
      this.detailInfo.chart1 = chart1;
      this.initEchart();
    },
    initEchart() {
      let vm = this;
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var series = [];
      seriesData.forEach((item) => {
        // item.value.forEach((item2,index) => {
        //   // item2 = Number(item2).toFixed(2);
        //   item.value[index] = Number(item2).toFixed(2);
        //   // item.value[index] = Number(item.value[index])
        // });
        // //console.log(item.value);
        var obj = {
          type: "bar",
          name: item.name,
          data: item.value,
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          smooth: false,
          showSymbol: false,
          emphasis: {
            focus: "series",
          },
          itemStyle:{

          }
          // barGap:0,
          // barWidth: 10,
        };
        if(item.name =='光伏'){
          obj.itemStyle.color = "rgb(60,189,50)"
        }
        if(item.name =='市电'){
          obj.itemStyle.color = "#dbdbdb"
        }
        //console.log(obj);
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "2%",
          right: " 2%",
          bottom: "7%",
          // top: '7%',
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        legend: {
          right: 0,
          top: "5%",
          //orient: "vertical",
          icon: "rect",
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            //console.log(params);
            let str = "";
            str += params[0].name + "<br>";
            params.forEach((item) => {
              str +=
                "<div>" +
                `<span style="display:inline-block;margin-right:4px;width:10px;height:10px;background-color:${item.color}">` +
                "</span>" +
                `<span style="text-align: left;">${item.seriesName}:</span>` +
                `<span style="text-align: right; margin-left: 10px;">${Number(
                  item.value
                ).toFixed(2)}</span>` +
                "</div>";
            });
            // str += `<div><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${params.color};margin-right:4px;"></span><span style="display:inline-block;margin-right:40px;">${params.value}</span></div>`;
            return str;
          },
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: {
          type: "value",
          name: this.commonLangHandler('powerBox3_consumption','能耗', this.getZEdata) + '(kWh)',
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
            padding: this.gridLeft,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              color: "#ebebee",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getItemList();
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox3 {
  .card-content1 {
    // margin-left: 16px;
    height: 44px;
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
